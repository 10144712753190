@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;800&display=swap");

.blog-navbar {
  position: sticky;
  top: 0;
  z-index: 50;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #28323a;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0 4px 6px rgba(31, 41, 55, 0.1);
  transition: all 0.5s ease;
  min-height: 85px;
}

.blog-navbar.dark {
  box-shadow: none;
  background-color: transparent;
}

@media (min-width: 640px) {
  .blog-navbar {
    padding-left: 0.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .blog-navbar {
    padding-left: 1rem;
    padding-right: 2rem;
  }
}

.blog-hero {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 640px) {
  .blog-hero {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .blog-hero {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.build-button {
  height: fit-content;
  padding: 0.25rem 1rem;
  text-decoration: none;
  background-color: rgba(137, 246, 250, 0.41);
}

.build-button:hover {
  background-color: rgba(137, 246, 250, 0.41);
  filter: brightness(1.2);
}

.blog-logo {
  font-family: "Sigmar One", sans-serif;
  color: #f8f8f2;
  letter-spacing: 2px;
  font-size: 30px;
  margin: 0;
  padding: 0 2px;
  text-shadow: 3px 3px rgba(244, 234, 234, 0.3);
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.hero-content {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 36rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;
  column-gap: 2rem;
  row-gap: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-size: 30px;
  display: flex;
  justify-content: space-around;
}

@media (min-width: 1024px) {
  .hero-content {
    max-width: 96rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .hero-content {
    column-gap: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.header-custom {
  display: inline;
  background-image: linear-gradient(
    to right,
    #c7d2fe 0%,
    #8ab4f8 50%,
    #c7d2fe 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  font-family: "Sigmar One", sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
  letter-spacing: -0.025em;
  color: transparent;
}

.subheader-custom {
  margin-top: 0.75rem;
  font-size: 1.5rem;
  letter-spacing: -0.025em;
  color: #64748b;
}

.hero-image {
  filter: drop-shadow(2px 4px 6px black) brightness(0.75);
}

.content-container {
  width: 100%;
}

@media (min-width: 1024px) {
  .content-container {
    width: 75%;
    padding-left: 2rem;
  }
}

.content-custom {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 96rem;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #fff;
  height: 100%;
}

@media (min-width: 640px) {
  .content-custom {
    padding-right: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .content-custom {
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .content-custom {
    padding-right: 3rem;
  }
}

.content-panel {
  display: none;
}

@media (min-width: 1024px) {
  .content-panel {
    position: relative;
    display: block;
    flex: none;
  }
}

.content-body {
  min-width: 0;
  max-width: 36rem;
  flex: 1 1 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 1024px) {
  .content-body {
    max-width: none;
    padding-left: 2rem;
    padding-right: 0;
  }
}

@media (min-width: 1280px) {
  .content-body {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

nav {
  font-size: 1rem;
  width: 16rem;
  padding-right: 2rem;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  line-height: 24px;
}

.nav-container {
  position: sticky;
  top: 4.5rem;
  margin-left: -0.125rem;
  height: calc(100vh - 4.5rem);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 0.125rem;
  background-color: #f5f7fa;
}

.nav-index {
  font-size: 1rem;
  width: 16rem;
  padding-right: 2rem;
}

@media (min-width: 1024px) {
  .nav-index {
    font-size: 0.875rem;
  }
}

@media (min-width: 1280px) {
  .nav-index {
    width: 18rem;
    padding-right: 4rem;
  }
}

.content-subheader {
  font-family: "Raleway", sans-serif;
  font-size: 1.25rem;
  font-weight: 800;
}

/* .content-subheader {
  font-family: "Raleway", sans-serif;
  font-size: 0.875rem; 
  font-weight: 800;
  color: #60a5fa;
} */

.content-header {
  font-family: "Sigmar One", sans-serif;
  font-weight: 500;
  font-size: 1.875rem; /* 30px */
  letter-spacing: -0.025em;
  color: #4e606c;
  border-bottom: 3px solid rgb(81 130 136);
  padding-bottom: 1rem;
  width: fit-content;
  margin-bottom: 2.5rem;
}

article {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #6a6a6a;
  line-height: 24px;
}

hr {
  border-color: #e2e8f042;
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.lead {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 1.125rem; /* 18px */
  line-height: 1.75;
}

.quick-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.quick-link {
  box-shadow: 5px 5px 5px #42525e;
  min-width: 300px;
  margin: 10px;
  font-family: "Sigmar One", sans-serif;
  color: #efefef;
  background-color: #4f606c;
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.quick-link:hover {
  filter: brightness(1.2);
  box-shadow: 3px 3px 3px #42525e;
  transition: all 0.3s;
}

.quick-link-header {
  font-family: "Sigmar One", sans-serif;
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.75;
  color: #fff;
}

.quick-link:active {
  transform: translateY(1px);
  box-shadow: 1px 1px 1px #42525e;
}

.nav-list {
  margin-top: 0.5rem;
  border-left: 3px solid #f3f4f6; /* border-slate-100 */
  padding-left: 0.75rem;
}

.nav-list.dark {
  border-color: #4b5563; /* border-slate-800 */
}

.nav-list > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

@media (min-width: 1024px) {
  /* lg breakpoint */
  .nav-list {
    margin-top: 1rem;
    border-color: #e5e7eb; /* border-slate-200 */
  }

  .nav-list > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
}

li {
  list-style: none;
}

.list-item-selected {
  display: block;
  width: 100%;
  position: relative;
  font-weight: 600;
  color: #60a5fa; /* text-sky-500 */
}

.list-item-selected::before {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 50%;
  height: 0.375rem;
  width: 0.375rem;
  transform: translateX(-35%);
  border-radius: 50%;
  background-color: #60a5fa; /* bg-sky-500 */
}

.list-item {
  display: block;
  width: 100%;
  padding-left: 0.875rem;
  position: relative;
  color: #3f3f46; /* text-slate-500 */
}

.list-item::before {
  content: "";
  pointer-events: none;
  position: absolute;
  left: -0.25rem;
  top: 50%;
  height: 0.375rem;
  width: 0.375rem;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: #cbd5e1; /* bg-slate-300 */
  display: none; /* hidden */
}

.list-item:hover {
  color: #2d3748; /* hover:text-slate-600 */
}

.list-item.selected {
  background-color: rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.content-body-paragraph {
  font-family: "Raleway", sans-serif;
  color: #737373;
  line-height: 24px;
  font-size: 18px;
}

.content-image {
  display: flex;
  margin: auto;
}

#blog-container b {
  cursor: pointer;
  color: #eca842;
  border-bottom: 2px dashed #eca842;
}

.expand-icon {
  padding-left: 1rem;
  color: white;
  cursor: pointer;
}

.expand-icon:hover {
  filter: brightness(0.75);
}

.learn-gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 0;
}

.learn-gallery-item {
  height: 300px;
  max-width: 300px;
  margin: 1rem 1.5rem;
  font-family: "Sigmar One", sans-serif;
  color: #efefef;
  background-color: #4f606c;
  font-size: 20px;
  border-radius: 25px;
  text-align: center;
  padding: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 5px 5px 5px rgba(31, 41, 55);
}

.learn-gallery-item:hover {
  filter: brightness(1.2);
  box-shadow: 0 4px 6px rgba(31, 41, 55, 0.1);
  transition: all 0.3s;
}

.learn-gallery-item > a {
  color: #efefef;
  text-decoration: none;
}

.learn-gallery-description {
  font-family: "Sigmar One", sans-serif;
  font-weight: 300;
  color: #fff;
  line-height: 24px;
  font-size: 16px;
}

.learn-gallery-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.learn-gallery-tag {
  font-family: "Sigmar One", sans-serif;
  font-weight: 300;
  color: #fff;
  line-height: 24px;
  font-size: 16px;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  margin: 0.5rem;
  background-color: rgb(138 59 113 / 63%);
}
