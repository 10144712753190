@import url("https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap");

html {
  background-color: #28323a;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.navbar,
.Main {
  margin: 8px;
  overflow: hidden;
}

.navbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header {
  font-family: "Sigmar One", sans-serif;
  color: #f8f8f2;
  letter-spacing: 2px;
  font-size: 30px;
  margin: 0;
  padding: 0 2px;
  text-shadow: 3px 3px rgba(244, 234, 234, 0.3);
}

.Main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.editor {
  height: 42.5vh;
}

.editor > div > div {
  border-radius: 15px;
}

.tab-container {
  display: flex;
  justify-content: flex-end;
}

.display {
  border-radius: 15px;
  flex-basis: 100%;
  width: 100%;
  margin-top: 1.5rem;
  height: 40vh;
}

.editor,
.display {
  border: 0.25rem solid #28323a;
}

#blocklyJSDiv,
#blocklyHTMLDiv,
#blocklyCSSDiv {
  width: 100%;
  height: 100%;
}

.blocklyToolboxContents {
  padding-bottom: 10px;
}

.blocklyToolboxDiv {
  z-index: 1;
}

.blocklyToolboxCategory {
  margin: 0.75rem 0;
}

.blocklyTreeRow {
  cursor: pointer;
  border: none !important;
}

.blocklyTreeLabel {
  background: #28323a;
  border-radius: 5px;
  padding: 4px;
  font-size: 14px;
  font-weight: 800;
  padding: 10px;
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
}

.blocklyToolboxCategory:hover {
  opacity: 0.5;
}

.blocklyTreeSelected:hover ~ .blocklyTreeLabel {
  background-color: #6d7174 !important;
}

.blocklyTreeSelected {
  background-color: rgb(56, 74, 86) !important;
}

.blocklyTreeRow:not(.blocklyTreeSelected):hover {
  background-color: rgb(56, 74, 86) !important;
}

.tab {
  color: white;
  font-family: "Raleway", sans-serif;
  background-color: #4e606c;
  width: fit-content;
  padding: 5px 15px;
  font-size: 14px;
  margin-right: 1rem;
  border-radius: 5px 5px 0 0 !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tab:hover {
  background-color: #62737e;
}

.export-button,
.load-button {
  font-family: "Sigmar One", sans-serif;
  width: fit-content;
  font-size: 16px;
  margin: 4px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 0 5px 10px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 20%);
  min-width: 100px;
}

.load-button {
  background-color: #4e606c;
  color: white;
}

.share-button {
  background-color: white;
  color: #465159;
}

.blocklyTreeIcon {
  width: 10px;
}

#template-container,
#pens-container,
.auth-modal {
  opacity: 0.98;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #303b44;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.51); */
}

#pens-container {
  padding: 2rem;
  max-height: 90vh;
}

.template-slides,
.pens-slides {
  height: 100%;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 20%);
  background-color: #aabccb;
  cursor: pointer;
}

.template-slides {
  margin: 4rem;
  width: 20%;
  height: 20%;
  min-height: 130px;
  max-height: 130px;
}

.pens-list {
  margin: 1rem;
  max-height: 60%;
  background-color: #a8bac9;
  overflow-y: scroll;
  border-radius: 15px;
  box-shadow: 0 9px 8px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 20%);
  padding: 1rem;
}

.pens-slides {
  margin: 0.5rem;
  min-width: 400px;
  height: fit-content;
  background-color: #4e606c;
}

.pens-title {
  text-align: center;
  font-family: "Sigmar One", sans-serif;
  color: #fff;
  letter-spacing: 2px;
  font-size: 24px;
  text-shadow: 3px 3px rgb(0 0 0 / 30%);
}

.pens-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-family: "Sigmar One", sans-serif;
  color: #434343;
}

.template-name {
  text-align: center;
  font-family: "Sigmar One", sans-serif;
  color: #434343;
  letter-spacing: 2px;
  font-size: 30px;
  text-shadow: 3px 3px rgb(0 0 0 / 30%);
}

.template-slides:hover {
  background-color: #dee9f2;
  box-shadow: 0 59px 78px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 20%);
  cursor: pointer;
}

.load-button:hover {
  background-color: #7c8a94;
  box-shadow: 0 59px 78px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 20%);
  cursor: pointer;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth-title,
.auth-subtitle {
  text-align: center;
  font-family: "Sigmar One", sans-serif;
  color: #fff;
  letter-spacing: 2px;
  font-size: 30px;
  text-shadow: 3px 3px rgb(0 0 0 / 30%);
  margin: 10px;
}

.auth-subtitle {
  font-size: 20px;
}

.auth-input {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  border: none;
  margin: 10px 0;
  padding: 0 10px;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
}

.auth-button {
  width: auto;
  height: 40px;
  border-radius: 5px;
  border: none;
  margin: 10px 0;
  padding: 0 10px;
  font-size: 16px;
  font-family: "Sigmar One", sans-serif;
  background-color: #4e606c;
  color: #fff;
  cursor: pointer;
}

.validation {
  text-align: center;
  font-family: "Raleway", sans-serif;
  color: red;
  letter-spacing: 2px;
  font-size: 14px;
  margin: 10px;
}

.full-screen {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

.tag {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}
.topright:before {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 180px solid #28323a;
  border-left: 180px solid transparent;
  position: absolute;
  bottom: 0;
  right: 0;
}
.topright:after {
  content: "Edit on Blockpen";
  position: absolute;
  bottom: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 120px;
  text-align: center;
  font-size: 20px;
  font-family: "Sigmar One", sans-serif;
}
.code-viewer {
  padding: 0.5rem 1rem;
  color: #efefef;
  background: rgb(34 45 50, 0.5);
  border: #d8ebebad solid 2px;
  width: 92%;
  height: 93%;
  border-radius: 15px;
}

pre {
  border: 0.3em solid rgb(40 50 58);
  color: white;
  background: #394956;
  text-shadow: black 0px -0.1em 0.2em;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0px;
  overflow: auto;
  border-radius: 0.5em;
  box-shadow: black 1px 1px 0.5em inset;
}

/* animations */
@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  12% {
    transform: translate(5px, -5px) rotate(5deg);
  }
  24% {
    transform: translate(5px, 0) rotate(5deg);
  }
  36% {
    transform: translate(5px, 5px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  62% {
    transform: translate(-5px, -5px) rotate(-5deg);
  }
  75% {
    transform: translate(-5px, 0) rotate(-5deg);
  }
  87% {
    transform: translate(-5px, 5px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.menu-overlay-container {
  position: relative;
}

.menu-overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20%;
  left: 10%;
  z-index: 50;
  background-color: #485762;
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0 59px 78px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 20%);
}

.menu-overlay button {
  margin: 10px 0;
}

.menu-button {
  padding: 8px;
  min-width: 150px;
}
