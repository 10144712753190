.loading-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(40, 50, 58, 0.99);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  margin: 0 0 2em;
  height: fit-content;
  width: fit-content;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

.loader > svg path,
.loader > svg rect {
  fill: #c6c6c6;
}
