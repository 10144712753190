.how-to-container,
.how-to-overview-container {
  position: fixed;
  background-color: #7c8a94;
  border-radius: 25px;
  box-shadow: 4px 4px 30px rgb(15 15 15 / 100%);
  z-index: 999;
}

.how-to-container {
  width: 400px;
  min-height: 250px;
}

.how-to-overview-container {
  top: calc(50% - 300px);
  left: calc(50% - 300px);
  width: 600px;
  min-height: 600px;
}

.how-to-container:before {
  content: "";
  position: absolute;
  left: -14px;
  top: 28px;
  border-style: solid;
  border-width: 15px 10px 15px 5px;
  border-color: rgba(0, 0, 0, 0) #7c8a94 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.how-to-container > div > h1 {
  font-family: "Sigmar One", sans-serif;
  font-size: 25px;
  font-weight: 100;
  color: #efefef;
  margin: 20px 25px;
}

.how-to-container > .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.open {
  position: fixed;
  width: 100px;
  height: 40px;
  left: 50%;
  top: -1000px;
  margin-left: -80px;
  margin-top: -30px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 30px;
  color: #444;
  z-index: 999;
  transition: all ease-out 0.6s;
}
.open:hover {
  border: 1px solid #aaa;
  box-shadow: 0 0 8px #ccc inset;
  transition: all ease-out 0.6s;
}
.how-to-container p {
  width: 350px;
  font-size: 16px;
  color: #efefef;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  line-height: 28px;
  float: left;
  z-index: 999;
  margin: 0;
}
.how-to-container .bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  z-index: 999;
  display: flex;
  width: 100%;
  bottom: 0;
  position: absolute;
}
.how-to-container .bottom .step {
  flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  width: 100%;
  height: 50px;
  background-color: #373942;
  border-bottom-left-radius: 6px;
  display: flex;
  border-radius: 0 0 0 15px;
}
.how-to-container .bottom .step ul {
  flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  list-style: none;
  height: 10px;
  margin: auto;
  padding-bottom: 4px;
  padding-left: 4px;
  display: flex;
  justify-content: center;
}
.how-to-container .bottom .step ul li {
  position: relative;
  height: 15px;
  width: 15px;
  margin: 0 10px;
  float: left;
  border-radius: 50%;
  background: none;
  border: 1px solid #535560;
  cursor: pointer;
}
.lesson-body span > b {
  color: #eca842;
  border-bottom: 2px dashed #eca842;
  cursor: pointer;
}

.how-to-container .bottom .step ul li.active {
  background-color: #fff;
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.78);
  transition: all 1s;
}

.how-to-container .bottom .step ul li:first-child:before {
  width: 0;
}
.how-to-container .bottom .step ul li:before {
  content: "";
  position: absolute;
  border-top: 1px solid #535560;
  top: 3px;
  width: 20px;
  left: -21px;
  top: 5px;
}

/* overview container */
.how-to-overview-container .bottom .step ul li.true {
  background-color: #7a7d86;
}
.how-to-overview-container .bottom .step ul li {
  cursor: pointer;
}
.how-to-overview-container .bottom .step ul li:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.how-to-overview-container .bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  z-index: 999;
  display: flex;
  width: 100%;
  bottom: 0;
  position: absolute;
}
.how-to-overview-container .bottom .step {
  flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  width: 100%;
  height: 50px;
  background-color: #373942;
  border-bottom-left-radius: 6px;
  display: flex;
  border-radius: 0 0 25px 25px;
}
.how-to-overview-container ul {
  flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  list-style: none;
  height: 10px;
  margin: auto;
  padding-bottom: 4px;
}
.how-to-overview-container ul li {
  position: relative;
  height: 25px;
  width: 25px;
  margin: 80px 20px 120px 80px;
  border-radius: 50%;
  background: #7c8a94;
  /* not yet completed */
  /* border: 5px dashed #535560; */
  display: flex;
  align-items: center;
  border: var(--border-circle-color);
}
.how-to-overview-container ul li:last-child:before {
  width: 70px;
  height: 0;
}
.how-to-overview-container ul li:before {
  content: "";
  position: absolute;
  /* dashed for not yet completed */
  /* border: 5px dashed rgba(83, 85, 96, 0.5); */
  border-left: 5px solid #535560;
  border-top: 4px dotted #535560;
  width: 65px;
  height: 140px;
  left: 10px;
  top: 12px;
  z-index: -1;
  border-left: var(--border-left-color);
  border-top: var(--border-connector-color);
}
.how-to-overview-container ul li p {
  margin: 0 -400px 0 75px;
  font-family: "Sigmar One";
  font-size: 20px;
  color: #efefef;
  background: #525561;
  border: 7px solid #525561;
  padding: 10px;
  width: 250px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 5px 5px 5px rgba(83, 85, 96, 0.75);
}

.how-to-overview-container ul li p:hover {
  filter: brightness(1.2);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}

.how-to-overview-container ul li.true {
  background-color: #7a7d86;
}
.how-to-overview-container ul li {
  cursor: pointer;
}
.how-to-overview-container ul li:hover {
  background-color: #5f6271;
  border-color: #5f6271;
}
.how-to-overview-container ul li.active {
  background-color: #fff;
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.78);
  transition: all 1s;
}
.how-to-overview-container .bottom .step {
  border-radius: 0 0 25px 25px;
}

.close {
  cursor: pointer;
}
.close:before,
.close:after {
  content: "";
  position: absolute;
  height: 13px;
  width: 13px;
  top: 26px;
  right: 31px;
  border-top: 5px solid #efefef;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.close:before {
  right: 40px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn {
  flex: 1;
  background-color: #92b8d8;
  font-family: "Sigmar One", sans-serif;
  border: 0;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  border-bottom-right-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 16px;
  border-radius: 0 0 15px 0;
}
.btn:hover {
  color: #4e606c;
  background-color: #efefef;
  transition: all 0.3s;
}
.btn:active {
  background-color: #5f8aaf;
}
.slider-container {
  width: 350px;
  margin: 0 25px 80px 25px;
  overflow: hidden;
}
.slider-turn {
  width: 10000px;
}

.full-page-modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(39, 48, 55, 0.97);
  z-index: 100;
}

.how-to-list {
  width: 750px;
  height: fit-content;
  padding: 40px 10px;
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 375px);
  z-index: 999;
  background-color: #485762;
  box-shadow: 4px 4px 30px rgb(15 15 15 / 100%);
  transition: all ease-out 0.6s;
  color: #efefef;
  border-radius: 15px;
}

.how-to-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Sigmar One", sans-serif;
  color: #efefef;
  background-color: rgb(0 0 0 / 30%);
  width: 300px;
  height: 200px;
  letter-spacing: 2px;
  font-size: 24px;
  border-radius: 15px;
  text-shadow: 3px 3px rgb(0 0 0 / 30%);
}

.how-to-list-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.nav-list-item {
  box-shadow: 10px 10px 10px #2a3f42;
  max-width: 450px;
  margin: 10px;
  font-family: "Sigmar One", sans-serif;
  color: #efefef;
  background-color: rgb(56, 74, 86);
  font-size: 20px;
  border-radius: 15px;
  text-align: center;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nav-list-item:hover {
  filter: brightness(1.2);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}

.lesson-title {
  font-family: "Sigmar One", sans-serif;
  color: #efefef;
  font-size: 24px;
  text-align: center;
  text-shadow: 3px 3px rgb(0 0 0 / 30%);
}

.how-to-overview-container .lesson-title {
  font-size: 30px;
  margin: 0;
  padding: 10px;
  border-radius: 25px;
}

.how-to-overview-container .header-container {
  border-radius: 25px 25px 0 0;
}

.lesson-body {
  text-shadow: 1px 1px 2.5px #373942;
}

.lesson-section {
  font-family: "Sigmar One", sans-serif;
  color: #efefef;
  font-size: 18px;
  text-shadow: 1px 1px 2.5px #373942;
}

@keyframes stretch-animation {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 0.9);
  }
  30% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.05, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}

.learn-tooltip {
  position: fixed;
  width: 260px;
  height: 40px;
  animation: stretch-animation 1s ease-out forwards 0.15s;
  box-shadow: 10px 10px 10px #2a3f42;
  max-width: 450px;
  margin: 10px;
  font-family: "Raleway", sans-serif;
  color: #efefef;
  background-color: rgb(56, 74, 86);
  font-size: 16px;
  border-radius: 15px;
  text-align: center;
  padding: 15px 15px 15px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  font-weight: 500;
}

.learn-tooltip::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 35px solid #394956;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  z-index: -1;
}

.learn-tooltip-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
  width: 40px;
  height: 25px;
  background: #eca842;
  border-radius: 5px;
  position: absolute;
  left: 20px;
  margin-left: 0;
  bottom: 25px;
  z-index: 0;
}
