#homepage-container {
}

.homepage-header {
  font-family: "Sigmar One";
  font-style: normal;
  font-weight: 400;
  font-size: 4rem;
  color: #28323a;
  margin-bottom: auto;
  margin-top: 5rem;
}

.homepage-header-custom {
  display: inline;
  font-family: "Sigmar One", sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
  letter-spacing: -0.025em;
  color: transparent;
}

.hero-content {
  column-gap: 0;
}

.homepage-graphic {
  display: block;
}

@media screen and (max-width: 768px) {
  .logo-container {
    justify-content: none;
  }
  .homepage-graphic {
    display: none;
  }

  .options-menu {
    display: none;
  }

  .blog-hero {
    padding-top: 8rem;
  }
}

#container {
  position: relative;
  filter: url(#threshold) blur(0px);
  margin-right: auto;
}

#text1,
#text2 {
  position: absolute;
  display: inline-block;
  width: 100%;

  font-family: "Sigmar One", sans-serif;
  font-size: 40pt;
  user-select: none;
}

.navitem {
  font-family: "Sigmar One", sans-serif;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  margin: 0 2rem;
  cursor: pointer;
  filter: drop-shadow(1px 2px 3px black);
}

.navitem:hover {
  filter: drop-shadow(0.5px 1px 1.5px #656a70) brightness(1.2);
}

.user-avatar {
  padding: 0.25rem;
  cursor: pointer;
}

.pts {
  font-family: Sigmar one;
  color: #fff;
  font-size: 24px;
  padding-right: 0.25rem;
}

.auth-form.dropdown {
  margin: 5px 0 10px 0;
}

.label {
  font-family: Sigmar One;
  color: #fff;
  font-size: 15px;
  padding: 0.25rem 0.25rem 0 0.25rem;
  margin-right: auto;
}

.email-login-button {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 14px;
  padding: 0.75rem 2rem;
  border-radius: 5px;
  margin: 1rem;
  border: none;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  cursor: pointer;
  width: 190px;
}

.email-login-button:hover {
  filter: brightness(0.8);
}

.homepage-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="2000" height="750" fill="none" viewBox="0 0 3500 2250"><g><path fill="%23F5F5F5" d="M802.691 79.42C1962.18 600.774 2309.06 211.828 2841.35 342.732c532.28 130.904-6.24 475.759 649.08 1193.298 655.32 717.53-2699.942 238.15-3112.675 238.15-412.732 0-734.553-2216.115 424.936-1694.76Z"/></g></svg>');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 90vh;
}

.about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="3000" height="2500" fill="none" viewBox="0 0 3250 2000"><g><path fill="%23F5F5F5" d="M802.691 79.42C1962.18 600.774 2309.06 211.828 2841.35 342.732c532.28 130.904-6.24 475.759 649.08 1193.298 655.32 717.53-2699.942 238.15-3112.675 238.15-412.732 0-734.553-2216.115 424.936-1694.76Z"/></g></svg>');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="2500" height="150" fill="none" viewBox="0 0 300 300"><g><path fill="%23F5F5F5" d="M802.691 79.42C1962.18 600.774 2309.06 211.828 2841.35 342.732c532.28 130.904-6.24 475.759 649.08 1193.298 655.32 717.53-2699.942 238.15-3112.675 238.15-412.732 0-734.553-2216.115 424.936-1694.76Z"/></g></svg>');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.homepage-info.svg-2 {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="2000" height="650" fill="none" viewBox="0 0 4000 2500"><g><path fill="%23F5F5F5" d="M929.165 1911.67C-778.218 1605.2 323.404 72.157 669.669 145.463 1015.93 218.77 1973.77 513.53 3209.28 72.157c1235.51-441.373 394.6 1262.893 0 1839.513-531.08 776.03-1474.77 144.55-2280.115 0Z"/></g></svg>');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.homepage-info.footer-svg {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="2000" height="650" fill="none" viewBox="0 0 3000 500"><g><path fill="%23F5F5F5" d="M929.165 1911.67C-778.218 1605.2 323.404 72.157 669.669 145.463 1015.93 218.77 1973.77 513.53 3209.28 72.157c1235.51-441.373 394.6 1262.893 0 1839.513-531.08 776.03-1474.77 144.55-2280.115 0Z"/></g></svg>');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.homepage-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
}

.action-button {
  width: auto;
  height: 40px;
  border-radius: 5px;
  border: none;
  font-family: "Sigmar One", sans-serif;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  background-color: #33768b;
  font-size: 30px;
  padding: 20px 120px 20px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.action-button:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5);
}

.footer-item {
  font-family: Raleway;
  margin: 2rem;
  font-size: 18px;
  text-decoration: none;
  color: black;
}

.footer-item:hover {
  color: #33768b;
}

h1.homepage-header-custom {
  font-size: 70px;
}

h3.homepage-header-custom {
  font-size: 70px;
}

a.action-button {
  font-size: 28px;
}

h3.homepage-header-custom.small {
  font-size: 50px;
}

h1.homepage-header-custom.small {
  font-size: 50px;
}

.image-action-container {
  position: relative;
  margin-left: auto;
  margin-right: 4rem;
}

.footer-container {
  margin-top: auto;
  margin-bottom: 1.5rem;
}

.copyright-text {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  color: #4a5568;
  font-family: Raleway;
}

.header-info-container {
  margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .homepage-content {
    width: 75%;
    margin-left: 2rem;
  }

  .header-info-container {
    margin-top: 4rem;
  }

  h1.homepage-header-custom.large {
    font-size: 40px;
  }

  h3.homepage-header-custom.large {
    font-size: 40px;
  }

  a.action-button {
    font-size: 22px;
  }

  h3.homepage-header-custom.small {
    font-size: 30px;
  }

  h1.homepage-header-custom.small {
    font-size: 30px;
  }

  .image-action-container {
    margin-right: 0;
  }

  .footer-container {
    margin-bottom: 0.5rem;
  }

  .copyright-text {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .homepage-content {
    width: 60%;
    margin-left: 2rem;
  }

  .header-info-container {
    margin-top: 2rem;
  }

  h1.homepage-header-custom {
    font-size: 45px;
    margin-left: 0;
  }

  h3.homepage-header-custom {
    font-size: 45px;
  }

  a.action-button {
    font-size: 24px;
  }

  h3.homepage-header-custom.small {
    font-size: 35px;
  }

  h1.homepage-header-custom.small {
    font-size: 35px;
  }

  .image-action-container {
    margin-right: 2rem;
  }
}

.info-text {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 2rem;
  border-radius: 50px;
  line-height: 1.75rem;
  color: #fff;
  background: rgb(32 102 120);
  filter: drop-shadow(2px 4px 6px black);
  padding: 4rem;
  letter-spacing: 1px;
}

.info-text > a {
  color: #fff;
  text-decoration: underline;
  font-weight: 500;
}

.about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .about-content {
    width: 100%;
  }

  .info-text {
    padding: 2.5rem;
  }
}
